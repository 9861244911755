<template>
	<div class="com-mid-content text-white">
		<div class="flex align-center justify-between">
			<div>
				<div class="midItemBox jb1">
					<div class="base-font-16">总人数</div>
					<div class="flex align-end  zdInfo">
						<div class="num item" style="padding-right:10px;">{{ item.memberNum }}</div>
					</div>
				</div>
				<div class="midItemBox jb1" style="margin-top:3px;">
					<div class="base-font-16">总设备</div>
					<div class="flex align-end zdInfo">
						<div class="num item" style="padding-right:10px;">{{ item.deviceNum }}</div>
					</div>
				</div>
			</div>
			<div>
				<div class="midCircle">
					<div class="midInfo text-center">
						<div class="text-bold" style="font-size:66px;">{{ item.deviceOnlineNum }}</div>
						<div class="base-font-18 margin-top-10 " >在线设备</div>
					</div>
					<el-image
					      style="width: 279px; height: 279px"
						  class="roteCircle"
					      :src="circleImage"
					      fit="fill"></el-image>
				</div>
			</div>
			<div class="">
				<div class="midItemBox jb2">
					<div class="base-font-16 text-right">总回收员数</div>
					<div class="flex align-end  zdInfo justify-end">
						<div class="num" style="padding-left:10px;">{{ item.collectorNum }}</div>
					</div>
				</div>
				<div class="midItemBox jb2" style="margin-top:3px;">
					<div class="base-font-16 text-right">总订单数</div>
					<div class="flex align-end zdInfo justify-end">
						<div class="num" style="padding-left:10px;">{{ item.orderNum }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="margin-top-15">
			<BlockBox title="最近7天订单统计" height="306">
				<template #content>
					<div style="padding:0 15px;box-sizing: border-box">
						<div id="chartFwqk" style="height:225px;"></div>
					</div>
				</template>
			</BlockBox>
		</div>
		<div class="margin-top-15">
			<el-row :gutter="20">
			  <el-col :span="12">
				  <BlockBox title="用户总下单占比" height="306">
				  	<template #content>
				  		<div style="padding:0 15px;box-sizing: border-box">
				  			<div id="charrZdfwzb" style="height:225px;"></div>
				  		</div>
				  	</template>
				  </BlockBox>
			  </el-col>
			  <el-col :span="12">
				  <BlockBox title="交易金额一周数据" height="306">
				  	<template #content>
				  		<div style="padding:0 15px;box-sizing: border-box">
				  			<div id="chartSZsjcl" style="height:225px;"></div>
				  		</div>
				  	</template>
				  </BlockBox>
			  </el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import echartMixins from "@/utils/resizeMixins";
	import BlockBox from '@/views/components/blockBox'
  import {
    centerData
  } from "@/api/api"
	export default {
		name: "cityGreenLand",
		components: {BlockBox},
		mixins: [echartMixins],
		data() {
			return {
        item:{},
				circleImage:require("../../assets/images/xsd/circle.png"),
				colors: ['#f0792a', '#4b3eeb', '#247bf1', "#67e591", '#05d3f8', '#c0f02a', ],
				ssfwList:[
					{name:'小程序端',data:[820, 932, 901, 934, 1290, 1330, 1320,820, 932, 901, 934, 1290, 1330, 1320, 932, 901, 934, 1290, 1330, 1320]},
					{name:'微信端',data:[520, 532, 901, 334, 290, 330, 1320,520, 732, 601, 634, 290, 330, 320,634, 290, 330, 320,634, 290, 330, 320]},
					{name:'网站端',data:[720, 632, 601, 534, 490, 730, 820,320, 932, 901, 934, 1290, 1330, 1320,320, 932, 901, 934, 1290, 1330]}
				]
	
			}
		},
		mounted() {
     this.changeData();

		},
		methods: {
      changeData(){
        let _self=this
        centerData().then(res => {
          _self.item=res.data;
          _self.$nextTick(function() {
            _self.drawFwqk();
            _self.drawYzsjcl()
            _self.drawZdfwzb()
          });
        });
      },
			drawFwqk() {
				this.chart = this.$echarts.init(document.getElementById("chartFwqk"));
        //let data=[{name: "11-01", value: 128},{name: "11-02", value: 158},{name: "11-03", value: 148},{name: "11-04", value: 188},{name: "11-05", value: 168},{name: "11-06", value: 178}]
        let data=this.item.orderList;
        let data1=this.item.postingList;
        let dateList=[];
        let dateValue=[];
        let dateValue1=[];
        data.forEach(obj=>{
          dateList.push(obj.name);
          dateValue.push(obj.value);
        })
        data1.forEach(obj=>{
          dateValue1.push(obj.value);
        })

				let option = {
					grid: {
						left: '3%',
						right: '3.5%',
						bottom: '3%',
						top: 30,
						containLabel: true
					},
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						 left: 'right'
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						axisTick: {
							show: false,
						},
						data:dateList,
						axisLabel: {
							textStyle: {
								color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
								fontSize: 10 //更改坐标轴文字大小
							}
						},
						splitLine: { //网格线
							lineStyle: {
								type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
								color: ['rgba(45,163,255,0.1)']
							},
							show: true //隐藏或显示
						},
						axisLine: {
							show: false, //隐藏或显示
							lineStyle: {
								color: '#2da3ff'
							}
						}
					},
					yAxis: {
						type: 'value',
						axisTick: {
							show: false,
						},
						axisLabel: {
							textStyle: {
								color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
								fontSize: 10 //更改坐标轴文字大小
							}
						},
						splitLine: { //网格线
							lineStyle: {
								type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
								color: ['rgba(45,163,255,0.1)']
							},
							show: true //隐藏或显示
						},
						axisLine: {
							show: false, //隐藏或显示
							lineStyle: {
								color: '#ffffff',
								fontSize: 10
							}
						}
					},
					series: [
						{
						name:"预约回收订单数/笔",
						type: 'line',
						smooth: true,
						data: dateValue,
						showSymbol: false,
						symbol: 'emptyCircle', // 几何圆
						symbolSize: 5,
						 itemStyle: {
						        color: '#03DBF4'
						    },
						areaStyle: { // 区域填充样式
							color: { // 填充的颜色 // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
								type: 'linear',
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [{
										offset: 0,
										color: '#03DBF4', // 0% 处的颜色
									},
									{
										offset: 1,
										color: 'rgba(37,234,255,0)' // 100% 处的颜色
									}
								],
								global: false, // 缺省为 false
							}
						},
					},
            {
              name:"智能垃圾箱订单数/笔",
              type: 'line',
              smooth: true,
              data: dateValue1,
              showSymbol: false,
              symbol: 'emptyCircle', // 几何圆
              symbolSize: 5,
              itemStyle: {
                color: '#03DBF4'
              },
              areaStyle: { // 区域填充样式
                color: { // 填充的颜色 // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0,
                    color: '#03DBF4', // 0% 处的颜色
                  },
                    {
                      offset: 1,
                      color: 'rgba(37,234,255,0)' // 100% 处的颜色
                    }
                  ],
                  global: false, // 缺省为 false
                }
              },
            }
					]
				};
				// echart图表自适应
				this.chart.setOption(option)
				window.addEventListener("resize", () => {
					this.chart.resize();
				});
			},
			
			drawYzsjcl() {
				this.chart = this.$echarts.init(document.getElementById("chartSZsjcl"));
        //let data=[{name: "11-01", value: 28},{name: "11-02", value: 58},{name: "11-03", value: 48},{name: "11-04", value: 88},{name: "11-05", value: 68},{name: "11-06", value: 78}]
        let data=this.item.orderMoneyList;
        let dateList=[];
        let dateValue=[];
        data.forEach(obj=>{
          dateList.push(obj.name);
          dateValue.push(obj.value);
        })

        let option = {
					grid: {
						left: '3%',
						right: '3.5%',
						bottom: '3%',
						top:20,
						containLabel: true
					},
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						type: 'category',
						
						axisTick: {
							show: false,
						},
						data:dateList,
						axisLabel: {
							textStyle: {
								color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
								fontSize: 10 //更改坐标轴文字大小
							}
						},
						splitLine: { //网格线
							lineStyle: {
								type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
								color: ['rgba(45,163,255,0.1)']
							},
							show: true //隐藏或显示
						},
						axisLine: {
							show: false, //隐藏或显示
							lineStyle: {
								color: '#2da3ff'
							}
						}
					},
					yAxis: {
						type: 'value',
						axisTick: {
							show: false,
						},
						axisLabel: {
							textStyle: {
								color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
								fontSize: 10 //更改坐标轴文字大小
							}
						},
						splitLine: { //网格线
							lineStyle: {
								type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
								color: ['rgba(45,163,255,0.1)']
							},
							show: true //隐藏或显示
						},
						axisLine: {
							show: false, //隐藏或显示
							lineStyle: {
								color: '#ffffff',
								fontSize: 10
							}
						}
					},
					series: [
						{
						name: '交易金额/元',
						type: 'bar',
						barWidth:9,
						data: dateValue,
						itemStyle: {
						       normal: {
						       	label: {
						       		show: true, //开启显示
						       		position: 'top', //在上方显示
						       		textStyle: {
						       			//数值样式
						       			color: 'white',
						       			fontSize: 12,
						       		},
						       	},
								color: { // 填充的颜色 // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [{
											offset: 0,
											color: 'rgba(5, 149, 196, 1)', // 0% 处的颜色
										},
										{
											offset: 1,
											color: 'rgba(18, 106, 196, 1)' // 100% 处的颜色
										}
									],
									global: false, // 缺省为 false
								}
						      }
							},
			
				
					}, 
					]
				};
				// echart图表自适应
				this.chart.setOption(option)
				window.addEventListener("resize", () => {
					this.chart.resize();
				});
			},
			drawZdfwzb() {
				this.chart = this.$echarts.init(document.getElementById("charrZdfwzb"));
        let data=[{name: "预约下单", value: this.item.orderNum},{name: "智能垃圾箱", value: this.item.postingNum}]
        let option = {
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} :<br/> {c}笔 ({d}%)'
					},
					legend: {
						orient: 'vertical',
						top: 'center',
						left: 'right',
						textStyle: { //图例文字的样式
							color: '#fff',
							fontSize: 12
						},
						formatter: function(name) {
							// 获取legend显示内容
							let data = option.series[0].data;
							let tarValue = 0;
							for (let i = 0, l = data.length; i < l; i++) {
								if (data[i].name == name) {
									tarValue = data[i].value;
								}
							}
			
							return name + ' ' + ' ' + tarValue + '笔';
						},
					},
					color: this.colors,
					series: [{
						name: '用户总下单占比',
						type: 'pie',
						radius: ['50%', '70%'],
						center: ['25%', '50%'],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: 16,
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: data,
					}]
				};
				// echart图表自适应
				this.chart.setOption(option)
				window.addEventListener("resize", () => {
					this.chart.resize();
				});
			},
			
		}
	}
</script>

<style lang="scss">
	.com-mid-content {
		min-height: 500px;
		padding: 40px 10px 15px 10px;
		box-sizing: border-box;
		
		& .midItemBox{width:15vw;box-sizing: border-box;height:110px;line-height:0.6;
			
			& .zdInfo{
				font-size:36px;padding-top:20px;
				& .num{font-size:36px;font-weight:bold;}
				& .item{align-items: flex-end;}
			}
		}
		.midItemBox.jb1{
			padding:27px 0 0 30px;
			background: -webkit-linear-gradient(left, rgba(6, 32, 74, 1), rgba(6, 32, 74, 0.5),  rgba(6, 32, 74, 0.2),rgba(6, 32, 74, 0));
			/* Safari 5.1 - 6.0 */
			background: -o-linear-gradient(right, rgba(6, 32, 74, 1), rgba(6, 32, 74, 0.5),  rgba(6, 32, 74, 0.2),rgba(6, 32, 74, 0));
			/* Opera 11.1 - 12.0 */
			background: -moz-linear-gradient(right, rgba(6, 32, 74, 1), rgba(6, 32, 74, 0.5),  rgba(6, 32, 74, 0.2),rgba(6, 32, 74, 0));
			/* Firefox 3.6 - 15 */
			background: linear-gradient(to right, rgba(6, 32, 74, 1), rgba(6, 32, 74, 0.5),  rgba(6, 32, 74, 0.2),rgba(6, 32, 74, 0));
		}
		.midItemBox.jb2{
			padding:27px 30px 0 0;
			background: -webkit-linear-gradient(left, rgba(6, 32, 74, 0), rgba(6, 32, 74, 0.3),  rgba(6, 32, 74, 0.5),rgba(6, 32, 74, 1));
			/* Safari 5.1 - 6.0 */
			background: -o-linear-gradient(right, rgba(6, 32, 74, 0), rgba(6, 32, 74, 0.3),  rgba(6, 32, 74, 0.5),rgba(6, 32, 74, 1));
			/* Opera 11.1 - 12.0 */
			background: -moz-linear-gradient(right, rgba(6, 32, 74, 0), rgba(6, 32, 74, 0.3),  rgba(6, 32, 74, 0.5),rgba(6, 32, 74, 1));
			/* Firefox 3.6 - 15 */
			background: linear-gradient(to right, rgba(6, 32, 74, 0), rgba(6, 32, 74, 0.3),  rgba(6, 32, 74, 0.5),rgba(6, 32, 74, 1));
		}
		
		.midCircle{width:279px;height:279px;border-radius:50%;position: relative;
			box-shadow:0 0 40px rgba(21, 88, 152, 0.2);
			background: -webkit-linear-gradient(top, rgba(0, 35, 82, 1), rgba(9, 20, 45, 1));
			/* Safari 5.1 - 6.0 */
			background: -o-linear-gradient(bottom, rgba(0, 35, 82, 1), rgba(9, 20, 45, 1));
			/* Opera 11.1 - 12.0 */
			background: -moz-linear-gradient(bottom, rgba(0, 35, 82, 1), rgba(9, 20, 45, 1));
			/* Firefox 3.6 - 15 */
			background: linear-gradient(to bottom, rgba(0, 35, 82, 1), rgba(9, 20, 45, 1));
			.midInfo{width:279px;height:279px;position: absolute;top:0;left:0;padding-top:75px;box-sizing: border-box;line-height: 1.1;}
		}
		.roteCircle{
			animation: rotateCircle 9s infinite linear;
			-webkit-animation: rotateCircle 9s infinite linear; /* Safari 与 Chrome */
		}
		
		
	}


</style>
